<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      :name="`custom_table_samples_statistics`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @filter="onFilter"
    />
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";

export default {
  name: "SampleStatisticsTable",
  components: {
    TableCustom
  },
  data: function() {
    return {
      isReviewer: false,
      rawData: {},
      dataTable: {
        visible: true,
        isLoading: false,
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterByColumn: true,
          columns: [
            "Requester",
            "Type",
            "Open",
            "Closed",
            "Deleted",
            "Items Quantity",
            "Barrels Selected",
            "Barrels Unselected",
            "Barrels Sold"
          ],
          filterable: [
            "Requester",
            "Type",
            "Open",
            "Closed",
            "Deleted",
            "Items Quantity",
            "Barrels Selected",
            "Barrels Unselected",
            "Barrels Sold"
          ],
          cellClasses: {
            Reviewed: [
              {
                class: "bg-warning",
                condition: row => row["Reviewed"] === "No"
              }
            ],
            Delivered: [
              {
                class: "bg-warning",
                condition: row => row["Delivered"] === "No"
              }
            ]
          },

          perPage: 50,
          disablePerPageDropdown: false,
          perPageValues: [],
          showCustomActions: true,
          showChildRows: true
        },

        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getData: async function(payload) {
      let self = this;

      //display spinner  by default
      if (!payload || !payload.silentUpdate) {
        self.dataTable.isLoading = true;
        self.dataTable.dataSet = [];
      }

      this.$api
        .post("sample-requests/statistics", payload)
        .then(response => {
          self.dataTable.isLoading = false;

          if (response.length === 0) return;

          self.dataTable.dataSet = response;

          self.$emit("loaded", self.dataTable.dataSet.length);
        })
        .catch(error => {
          console.log(error);
          self.dataTable.isLoading = false;
          self.$form.msgBoxOk("Error occured");
        });
    },
    onFilter() {
      this.dataTable.totalRecords = this.$refs.dataTable.data.length;
    }
  }
};
</script>

<style scoped></style>
