<template>
  <div class="animated fadeIn">
    <b-card no-body>
      <b-container fluid class="pb-3">
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="sample-statistics"
              :show-labels="false"
              :load-dictionaries="loadDictionaries"
              :districts="filteringPanel.districts"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              @search="getData"
              @change="filterData"
              @dict-loaded="onFilteringPanelLoad"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <b-row v-show="dataTable.visible">
          <b-col>
            <sample-statistics-table ref="sample-statistics" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import FilteringPanel from "@/components/FilteringPanel";

import SampleStatisticsTable from "@/views/PosAndSamples/SampleStatisticsTable";

export default {
  name: "SampleStatistics",
  components: {
    FilteringPanel,
    SampleStatisticsTable
  },
  data: function() {
    return {
      filteringPanel: {
        loaded: false,
        selected: {},
        districts: [],
        filters: [
          {
            type: "select",
            title: "Requested By",
            name: "requested_by",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            dataType: "string",
            tooltip: "Responsible user name",
            multiple: true
          },
          {
            type: "select",
            title: "Type",
            name: "sample_type",
            trackby: "id",
            label: "label",
            options: [
              {
                id: 1,
                label: "POS"
              },
              {
                id: 2,
                label: "Sample"
              },
              {
                id: 3,
                label: "Barrel"
              }
            ],
            selected: {},
            dataType: "string",
            tooltip: "Sample type",
            multiple: true
          },
          {
            type: "daterange",
            defaultRange: "This year",
            title: "Request Period",
            name: "sample_request_period",
            dataType: "datetime",
            tooltip: "Request period",
            allowEmpty: false
          }
        ]
      },
      dataTable: {
        visible: true
      }
    };
  },
  computed: {},
  mounted() {},
  methods: {
    async loadDictionaries() {
      let self = this;

      const users = async () => {
        let response = await this.$api.get("users");

        self.filteringPanel.filters.find(
          f => f.name === "requested_by"
        ).options = response.map(u => ({
          id: u.id,
          label: u.full_name
        }));
      };

      await Promise.all([users()]);
    },

    onFilteringPanelStateChange() {
      this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder
        .visible;
    },
    onFilteringPanelLoad() {
      //if (this.$refs.filteringPanel.panelState.isCustomFiltersBar) this.getData();
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },

    filterData: function(e) {
      this.filteringPanel.selected = e;
    },
    getData() {
      let fp = this.$refs.filteringPanel;

      if (!fp.isSearchAllowed()) return;

      this.dataTable.isLoading = true;

      this.dataTable.dataSet = [];

      let f = fp.selected;

      let payload = {
        requested_by: f.requested_by ? f.requested_by.map(i => i.label) : [],
        sample_type: f.sample_type ? f.sample_type.map(i => i.label) : [],
        sample_request_period: f.sample_request_period
      };

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }

      this.$refs["sample-statistics"].getData(payload);
    }
  },
  watch: {}
};
</script>

<style>
.fa-toolbar {
  color: #0000008a;
}
</style>
